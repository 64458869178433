/**
 * Given an index and an array, return a string that represents the separator between the current
 * element and the next element in the array.
 * @param index - The index of the current item in the array.
 * @param array - the array to be converted
 * @returns {String} a string that represents the separator between the current element and the next element in the array
 */
export function getArrayAsTextSeparator(index: number, array: Array<any>): string {
  if (index < array.length - 1) {
    if (array.length > 2) {
      return ', '
    }
    return ' & '
  }
  return ' '
}

/**
 * Given an array, return a string representation of the array.
 * @param array - The array to convert to text.
 * @param [valueFormat] - A function that takes a value and returns a string.
 * @returns The array as text.
 */
export function getArrayAsText(array: Array<any>, valueFormat = (val: any): string => val) {
  if (!array || !array.length) {
    return ''
  }

  let arrayAsText = ''

  array.forEach((value, index) => {
    arrayAsText += valueFormat(value) + getArrayAsTextSeparator(index, array)
  })

  return arrayAsText
}

/**
 * Capitalise the first letter of a word.
 * @param {String} word - the word to be capitalised
 * @returns {String} The function capitalise is returning the word.
 */
export function capitalise(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

/**
 * Convert a string from kebab-case to camelCase.
 * @param {String} text - The string to be converted.
 * @returns {String} returned a camelCase string.
 */
export function kebabCaseToCamelCase(text: string) {
  return text
    .toLowerCase()
    .split('-')
    .map((word, index) => (index === 0 ? word : capitalise(word)))
    .join('')
}
