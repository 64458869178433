<template>
  <div class="bg-tertiary p-1 text-xs dark:bg-purple dark:text-white">
    <span v-if="!shorthand">Release: </span>{{ config.public.dockerImageTag }}
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

withDefaults(defineProps<{ shorthand?: boolean }>(), { shorthand: false })
</script>
