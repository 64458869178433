<template>
  <div class="flex items-center space-x-3">
    <UAvatar
      :src="image"
      :alt="actualName?.toUpperCase()"
      :icon="actualName || !icon ? undefined : icon"
      size="sm"
      :ui="ui"
    />

    <div
      v-if="showName"
      class="w-44 truncate text-left font-semibold lg:text-sm dark:text-white"
    >
      {{ name }}
      <div
        v-if="email"
        class="truncate text-xs font-normal dark:text-gray-400"
      >
        {{ email }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name?: string
    image?: string
    email?: string
    icon?: string
    showName?: boolean
    ui?: object
  }>(),
  {
    showName: false,
    icon: 'i-mdi-account',
    name: undefined,
    image: undefined,
    email: undefined,
    ui: () => ({})
  }
)

const actualName: ComputedRef<string | undefined> = computed(() => {
  if (props.name && props.name !== props.email) {
    return props.name
  }
  return undefined
})
</script>
